import React, {useState,useCallback,useEffect} from 'react';
import * as styles from './FirstSection.module.scss';
import {Image} from "../../../../hooks/gatsbyImg";
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'

const WhyFirstReason = () => {
 return (
        <div className={`${styles.container} ${styles.first}`}>
            <div className={styles.innerContainer}>
                <div className={styles.text}>
                    <p className={styles.number}>01.</p>
                    <h3 className={styles.title}>Look your professional best </h3>
                    <p className={styles.description}>Online or real- Combine  the best from both worlds. Efficient, engaging, connective, and creative. Master WiZR was created to improve the virtual space and go beyond normal meetings. Shine professionally.</p>
                </div>
                <div className={styles.background}>
                    <div data-aos='fade-left' data-aos-duration="-1000">
                   <Image  filename='women-1.png' className={styles.image} />
                   </div>
                </div>
            </div>
        </div>
    );
};

export default WhyFirstReason;
