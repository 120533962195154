// extracted by mini-css-extract-plugin
export var animationPart = "WhyReason-module--animationPart--PSn-c";
export var blackBackground = "WhyReason-module--blackBackground--bVYUy";
export var blueBackground = "WhyReason-module--blueBackground--rlUWS";
export var container = "WhyReason-module--container--OP-aq";
export var description = "WhyReason-module--description--JwQfS";
export var eighth = "WhyReason-module--eighth--rwLCm";
export var fifth = "WhyReason-module--fifth--HFOwv";
export var first = "WhyReason-module--first--1-hhi";
export var fourth = "WhyReason-module--fourth--tC7t7";
export var grayToWhite = "WhyReason-module--grayToWhite--VcILZ";
export var image = "WhyReason-module--image--F0RG7";
export var innerContainer = "WhyReason-module--innerContainer--WA94S";
export var ninth = "WhyReason-module--ninth--+RJIf";
export var number = "WhyReason-module--number--7Fh61";
export var second = "WhyReason-module--second--YDnXS";
export var seventh = "WhyReason-module--seventh--d5BzX";
export var sixth = "WhyReason-module--sixth--01Ci4";
export var subtext = "WhyReason-module--subtext--o+wYO";
export var tenth = "WhyReason-module--tenth--3VvQA";
export var text = "WhyReason-module--text--12vDF";
export var third = "WhyReason-module--third--G-9ul";
export var title = "WhyReason-module--title--GrJQI";
export var whiteBackground = "WhyReason-module--whiteBackground--dFdZw";
export var whiteColor = "WhyReason-module--whiteColor--tpZ59";