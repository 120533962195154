import React, {useCallback, useEffect, useState} from 'react';
import * as styles from './SecondSection.module.scss';
import {Image} from "../../../../hooks/gatsbyImg";
import 'react-slideshow-image/dist/styles.css'
import img from '../../../../images/design-like-a-pro.webp'

const WhySecondReason = () => {
    return (
        <div className={`${styles.container} ${styles.second}`}>
            <div className={styles.innerContainer}>
                <div className={styles.text}>
                    <p className={styles.number}>02.</p>
                    <h3 className={styles.title}>Design like a Pro</h3>
                    <p className={styles.description}>Create presentations in a snap. Access all your material with our intuitive presentation tool, Slide Customizer, extract the best and create new presentations in a no time. Feel and look like a Pro.</p>
                </div>
                <div className={styles.background}>
                    <div data-aos="fade-up"
     data-aos-anchor-placement="top-bottom" data-aos-delay='100' style={{ position:'relative' }}>
                        <img src={img} className={styles.image} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default WhySecondReason;