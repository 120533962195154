import React from 'react';
import * as styles from './WhyBanner.module.scss';
import HeroBanner from "../../atoms/HeroBanner";

const WhyBanner = () => {
    return (
        <div>
            <HeroBanner filename="10xwhy-bg.webp" extraClass="whyPage">
                <h1 data-aos="zoom-in" data-aos-delay="100" className={styles.title}>Transform the way you communicate today</h1>
                <p data-aos="zoom-in"className={styles.subtitle} data-aos-delay="200">Get the WiZR tools for the best results.</p>
                <a data-aos="zoom-in" data-aos-delay="300" href="/get-started" className={styles.button}>
                    <div className={styles.gray}>
                        <div className={styles.text}>
                            <p></p>
                        </div>
                    </div>
                </a>
            </HeroBanner>
        </div>
    );
};

export default WhyBanner;
