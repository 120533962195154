// extracted by mini-css-extract-plugin
export var background = "SecondSection-module--background--Bco2+";
export var container = "SecondSection-module--container--hB270";
export var description = "SecondSection-module--description--Qt6Wu";
export var image = "SecondSection-module--image--8KzXA";
export var innerContainer = "SecondSection-module--innerContainer--xGQYI";
export var number = "SecondSection-module--number--HaasN";
export var second = "SecondSection-module--second--P9fsa";
export var text = "SecondSection-module--text--1+w+v";
export var title = "SecondSection-module--title--7ar-s";
export var topimg = "SecondSection-module--topimg--8zjoO";